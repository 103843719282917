import React, { useEffect, useRef, useState } from 'react';
import { Colors } from '../../helpers/constants';
import useScrollPosition from '../../helpers/useScrollPosition';
import styles from './angled-rectangle.module.sass';

interface Props {
  bgcolor?: string;
  color?: string;
}

const AngledRectangle = ({ bgcolor, color }: Props) => {
  const wrapperRef = useRef<HTMLDivElement>();
  const backColor = bgcolor ? bgcolor : Colors.BROWN_RUST;
  const frontColor = color ? color : Colors.WHITE_FLORAL;
  const scrollPosition = useScrollPosition();
  const [rotation, setRotation] = useState(0);
  const [position, setPosition] = useState(10);

  useEffect(() => {
    if (!wrapperRef || !wrapperRef.current) return;

    const { y } = wrapperRef.current.getBoundingClientRect();
    const { innerHeight } = window;
    let percentage = 1 - y / innerHeight;
    if (percentage < 0) percentage = 0;
    if (percentage > 1) percentage = 1;
    const { innerWidth } = window;
    const maxRotation = innerWidth <= 500 ? 18 : window.innerWidth <= 768 ? 15 : 9;

    const newRotation = (1 - percentage) * -maxRotation;
    const newPosition = (1 - percentage) * 15;
    setRotation(newRotation);
    setPosition(newPosition);
  }, [scrollPosition]);

  return (
    <div
      ref={wrapperRef}
      className={styles.transition}
      style={{ backgroundColor: backColor }}
    >
      <div
        className={styles.angle}
        style={{
          backgroundColor: frontColor,
          transform: `translate3d(0, ${position}vh, 0) rotate(${rotation}deg)`,
        }}
      />
    </div>
  );
};

export default AngledRectangle;
