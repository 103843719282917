//@flow
import React from 'react';
import { RichText } from 'prismic-reactjs';
import { PrismicHomeAbout } from '../../types/prismic';
import { Colors } from '../../helpers/constants';
import AngledRectangle from '../base/AngledRectangle';
import AboutPageBlock from './AboutPageBlock';
import styles from './about-pledge.module.sass';

type Props = PrismicHomeAbout;

const AboutPledge = ({ data }: Props) => {
  const { about_header, about_description, pages } = data;

  return (
    <>
      <AngledRectangle bgcolor={Colors.NUTMEG} />
      <section className="full-width column center">
        <article className={`inner-width inner-width-padding`}>
          <div className={styles.copy}>
            <RichText render={about_header} />
            <RichText render={about_description} />
          </div>
          <div className={styles.container}>
            {pages.map((item, index) => (
              <AboutPageBlock key={`${index}`} data={item} />
            ))}
          </div>
        </article>
      </section>
    </>
  );
};

export default AboutPledge;
