//@flow
import React, { useEffect, useRef, useState } from 'react';
import Head from 'next/head';
import { RichText } from 'prismic-reactjs';
import { useWindowSize } from '../../hooks';
import { PrismicHomeAbout } from '../../types/prismic';
import styles from './instagram-embed.module.sass';

type Props = PrismicHomeAbout;

const InstagramEmbed = ({ data }: Props) => {
  const businessesRef = useRef<HTMLDivElement>();
  const { windowWidth } = useWindowSize();
  const [businessOverflow, setBusinessOverflow] = useState(false);
  const [hasWindow, setHasWindow] = useState(false);

  const { footer_copy } = data;

  useEffect(() => {
    setHasWindow(true);
  }, []);

  useEffect(() => {
    if (!businessesRef || !businessesRef.current) return;
    setBusinessOverflow(businessesRef.current.scrollWidth > windowWidth);
  }, [windowWidth]);

  return (
    <>
      <div
        ref={businessesRef}
        className={`${styles.businesses} ${
          businessOverflow ? styles.overflown : ''
        }`}
      >
        <div
          className="embedsocial-hashtag full-width"
          data-ref="f8912048d76a54c1fcba88a629faa40ab12972e0"
        />
        {hasWindow && (
          <Head>
            <script
              src="https://embedsocial.com/cdn/ht.js"
              id="EmbedSocialHashtagScript"
            />
          </Head>
        )}
        {/* {renderBusinessImages()} */}
      </div>
      <div className={`inner-width inner-width-padding center ${styles.footer}`}>
        <RichText render={footer_copy} />
      </div>
    </>
  );
};

export default InstagramEmbed;
