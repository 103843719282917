import Link from 'next/link';
import React, { useEffect, useRef, useState } from 'react';
import { Routes } from '../../helpers/constants';
import { PrismicPledgedCompanies } from '../../types/prismic';
import WaveScroller from '../base/WaveScroller';
import styles from './pledged-companies.module.sass';

type Props = {
  pledged: PrismicPledgedCompanies;
};

const PledgedCompanies = ({ pledged }: Props) => {
  const containerRef = useRef<HTMLDivElement>();
  const [isIntersecting, setIntersecting] = useState(true);

  const {
    data: { landing_component, landing_button, header, company },
  } = pledged;

  const lastItem = Math.round(company.length / 2);
  const topRow = company.slice(0, lastItem);
  const bottomRow = company.slice(lastItem, company.length);
  const animationDuration = topRow.length * 5;

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIntersecting(entry.isIntersecting);
      },
      { threshold: 0.01 }
    );
    observer.observe(containerRef.current);
    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <section ref={containerRef} className="full-width">
      <WaveScroller min={30} />
      <div className={styles.container}>
        <div className={styles.panorama}>
          <div
            className={`${styles.ltr} ${isIntersecting ? styles.animate : ''}`}
            style={{
              animationDuration: `${animationDuration}s`,
              animationPlayState: isIntersecting ? 'running' : 'paused',
            }}
          >
            {topRow.map((item, index) => (
              <div
                key={`${index}`}
                className={styles.img}
                style={{ animationPlayState: isIntersecting ? 'running' : 'paused' }}
              >
                <img src={item.thumb.url} alt={item.thumb.alt} />
              </div>
            ))}
            {topRow.map((item, index) => (
              <div
                key={`${index}`}
                className={styles.img}
                style={{ animationPlayState: isIntersecting ? 'running' : 'paused' }}
              >
                <img src={item.thumb.url} alt={item.thumb.alt} />
              </div>
            ))}
          </div>
        </div>
        <section
          className={`inner-width inner-width-padding center text-center ${styles.copy}`}
        >
          <div className="max-780">
            <h4>{landing_component}</h4>
            <Link href={Routes.PLEDGED}>
              <a className="button primary">{landing_button}</a>
            </Link>
          </div>
        </section>
        <div className={`${styles.panorama} ${styles.bottom}`}>
          <div
            className={`${styles.rtl} ${isIntersecting ? styles.animate : ''}`}
            style={{
              animationDuration: `${animationDuration}s`,
              animationPlayState: isIntersecting ? 'running' : 'paused',
            }}
          >
            {bottomRow.map((item, index) => (
              <div
                key={`${index}`}
                className={styles.img}
                style={{ animationPlayState: isIntersecting ? 'running' : 'paused' }}
              >
                <img src={item.thumb.url} alt={item.thumb.alt} />
              </div>
            ))}
            {bottomRow.map((item, index) => (
              <div
                key={`${index}`}
                className={styles.img}
                style={{ animationPlayState: isIntersecting ? 'running' : 'paused' }}
              >
                <img src={item.thumb.url} alt={item.thumb.alt} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default PledgedCompanies;
