import React from 'react';
import { Client } from '../prismic-configuration';
import { PrismicPledgedCompanies } from '../types/prismic';
import {
  AboutPledge,
  BlackBrands,
  InstagramEmbed,
  LandingHeader,
  PledgedCompanies,
} from '../components/home';
import { PageWrapper } from '../components/base';

interface Props {
  doc: any;
  pledged: PrismicPledgedCompanies;
}

const AboutPledgePage = ({ doc, pledged }: Props) => {
  return (
    <PageWrapper containerClass="no-overflow">
      <LandingHeader data={doc.data} />
      <PledgedCompanies pledged={pledged} />
      <BlackBrands data={doc.data} />
      <AboutPledge data={doc.data} />
      <InstagramEmbed data={doc.data} />
    </PageWrapper>
  );
};

export async function getServerSideProps() {
  const client = Client();
  const doc = await client.getSingle('homepage', {});
  const pledged = await client.getSingle('pledged-companies', {});

  return {
    props: {
      doc,
      pledged,
    },
  };
}

export default AboutPledgePage;
