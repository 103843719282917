//@flow
import React from 'react';
import Link from 'next/link';
import { RichText } from 'prismic-reactjs';
import { Colors, Routes } from '../../helpers/constants';
import { PrismicHomeBrands } from '../../types/prismic';
import { TwoImagesWithBlob, WaveScroller } from '../base';
// import SectionLabel from '../base/SectionLabel';
import styles from './black-brands.module.sass';

type Props = PrismicHomeBrands;

const BlackBrands = ({ data }: Props) => {
  const {
    brands_header,
    brands_description,
    brands_image_one,
    brands_image_two,
    brands_button_label,
    brands_button_link,
    brands_partner_label,
    brands_partner_image,
  } = data;

  const brandsHref = brands_button_link
    ? brands_button_link.type === 'search'
      ? Routes.SHOP_BLACK_SEARCH
      : `/${brands_button_link.uid}`
    : null;

  return (
    <>
      <WaveScroller color={Colors.NUTMEG} stationary={-8} />
      <section className="full-height full-width bg-nutmeg column center no-overflow">
        <article className={`inner-width inner-width-padding ${styles.container}`}>
          <TwoImagesWithBlob className={styles.images}>
            {({ imgOne, imgTwo }) => {
              return (
                <>
                  <img
                    className={imgOne}
                    src={brands_image_one.url}
                    alt={brands_image_one.alt}
                  />
                  <img
                    className={imgTwo}
                    src={brands_image_two.url}
                    alt={brands_image_two.alt}
                  />
                </>
              );
            }}
          </TwoImagesWithBlob>
          <div className={styles.copy}>
            <RichText render={brands_header} />
            <div className={`${styles.partner} column`}>
              {brands_partner_label && <p>{brands_partner_label}</p>}
              {brands_partner_image && (
                <img src={brands_partner_image.url} alt={brands_partner_image.alt} />
              )}
            </div>
            <RichText render={brands_description} />
            {brands_button_label && brands_button_label.length > 0 && brandsHref && (
              <Link href={brandsHref}>
                <a className="button secondary">{brands_button_label}</a>
              </Link>
            )}
          </div>
        </article>
      </section>
    </>
  );
};

export default BlackBrands;
