//@flow
import Link from 'next/link';
import React from 'react';
import { Routes } from '../../helpers/constants';
import { HomeAboutPage } from '../../types/prismic';
import FixedRatioImage from '../base/FixedRatioImage';
import styles from './about-pledge.module.sass';

type Props = {
  data: HomeAboutPage;
};

const AboutPageBlock = ({ data }: Props) => {
  const { label, image, page } = data;

  const slug = page.slug === 'homepage' ? Routes.HOME : `/${page.slug}`;

  return (
    <Link href={slug}>
      <a className={styles.thumb}>
        <FixedRatioImage image={image.url} ratio="1x1" transparent />
        <p className={styles.label}>{label}</p>
      </a>
    </Link>
  );
};

export default AboutPageBlock;
