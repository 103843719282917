//@flow
import React, { useEffect, useState } from 'react';
import { RichText } from 'prismic-reactjs';
import { PrismicLanding } from '../../types/prismic';
import { useWindowSize } from '../../hooks';
import styles from './landing-header.module.sass';
import Link from 'next/link';

type Props = PrismicLanding;

const LandingHeader = ({ data }: Props) => {
  const { header, description, background_drawings } = data;
  const [hasWindow, setHasWindow] = useState(false);
  const { windowWidth } = useWindowSize();

  useEffect(() => {
    setHasWindow(true);
  }, []);

  const renderImages = () => {
    const items = [];
    const leni = background_drawings.length;
    let item;
    for (let i = 0; i < leni; i += 1) {
      const top = -200;
      let left = Math.random() * 50;
      if (i % 2) left += 50;
      if (left < 50 && left > 30) {
        left -= 10;
      } else if (left > 50 && left < 70) {
        left += 10;
      }

      if (windowWidth >= 1920) {
        left =
          ((1920 * (left / 100) + (windowWidth - 1920) / 2) / windowWidth) * 100;
      }

      const image = background_drawings[i].image;

      item = (
        <div
          key={`drawing-${i}`}
          className={styles.icon}
          style={{
            backgroundImage: `url(${image.url})`,
            top: `${top}px`,
            left: `${left}vw`,
            animationDuration: `45s`,
            animationDelay: `${i * (45 / leni) - 45}s`,
          }}
        />
      );
      items.push(item);
    }

    return items;
  };

  return (
    <section className="full-height full-width no-overflow center bg-rust">
      <div className={`inner-width inner-width-padding ${styles.container}`}>
        {hasWindow && renderImages()}
        <div className={styles.content}>
          <article className={styles.copy}>
            <RichText render={header} />
            <RichText render={description} />
            {data.header_button_url && data.header_button_url.uid && (
              <div>
                <Link href={`/${data.header_button_url.uid}`}>
                  <a className={`button secondary ${styles.holidayButton}`}>
                    {data.header_button_label}
                  </a>
                </Link>
              </div>
            )}
          </article>
          {/* <figure className={styles.shapecontainer}>
            <img className={styles.shape} src="/images/landing-header-shape.svg" />
          </figure> */}
        </div>
      </div>
    </section>
  );
};

export default LandingHeader;
